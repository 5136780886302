
import Vue from 'vue';
import { RefreshToSeeUpdatedDate } from '../constants';

export default Vue.extend({
  name: 'commonConnectionSelectedAccounts',
  props: ['isLoading', 'connectionData', 'editRights'],
  data() {
    return {
      RefreshToSeeUpdatedDate,
    };
  },
  computed: {
    hasFullEdit(): boolean {
      return this.editRights?.hasFullEdit;
    },
    hasBasicEdit(): boolean {
      return this.editRights?.hasBasicEdit;
    },
  },
  methods: {
    removeItem(itemId: string, connectionId: string): void {
      this.$emit('removeItem', itemId, connectionId);
    },
  },
});
